import React from 'react';
import './App.scss';
import Home from './components/Home/Home';
import Navigation from './components/Navigation/Navigation';
import NavigationN from './components/NavigationN/NavigationN';
import AboutUs from './components/AboutUs/AboutUs';
import JoinUs from './components/JoinUs/JoinUs';
import OurEquipment from './components/OurEquipment/OurEquipment';
import LeaseOrRent from './components/LeaseOrRent/LeaseOrRent';
import RepairShop from './components/RepairShop/RepairShop';
import TrucksOnSale from './components/TrucksOnSale/TrucksOnSale';
import TrucksOnSaleN from './components/TrucksOnSaleN/TrucksOnSaleN';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import Divider from './components/Divider/Divider';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init();

  return (
    <div className="App">
      <NavigationN />
      {/* <Navigation /> */}
      <Home />
      <Divider />
      <AboutUs />
      <Divider />
      <JoinUs />
      <Divider />
      <OurEquipment />
      <Divider />
      <LeaseOrRent />
      <Divider />
      <RepairShop />
      <Divider />
      {/* <TrucksOnSale /> */}
      <TrucksOnSaleN />
      <Divider />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
