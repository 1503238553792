import React from 'react';
import './JoinUs.scss';
import ownerOperatorImg from '../../assets/images/owner-operator.jpg'
import driverInsideImg from '../../assets/images/driver.jpg';
import Application from '../Application/Application';

const JoinUs: React.FC = () => {

  return (
    <div className="JoinUs" id="joinUs">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a className="nav-link active" id="owner-operator-tab" data-toggle="tab" href="#owner-operator" role="tab" aria-controls="owner-operator" aria-selected="true">Owner operator</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" id="driver-tab" data-toggle="tab" href="#driver" role="tab" aria-controls="driver" aria-selected="false">Driver</a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">

        <div className="tab-pane fade show active" id="owner-operator" role="tabpanel" aria-labelledby="owner-operator-tab">
          <div className="row no-gutters">
            <div className="col-lg-7 col-12 banner" data-aos="fade-right">
              <img src={ownerOperatorImg} alt='Owner operator image' />
            </div>

            <div className="col-lg-5 col-12">
              <h3>Owner operator</h3>
              <ul>
                <li>88% paid of each load</li>
                <li>Great prices for trailer rental</li>
                <li>Direct deposit every Friday</li>
                <li>Light loads – Auto parts, UPS, expedite…</li>
                <li>24/7 dispatch – Experienced, dedicated and friendly dispatchers</li>
                <li>Fuel card</li>
                <li>No touch freight</li>
                <li>Safety bonus</li>
              </ul>

              <Application position="Owner operator"/>
            </div>

          </div>
        </div>

        <div className="tab-pane fade" id="driver" role="tabpanel" aria-labelledby="driver-tab">
          <div className="row no-gutters">
            <div className="col-lg-7 col-12 banner" data-aos="fade-right">
              <img src={driverInsideImg} alt='Driver image' />
            </div>

            <div className="col-lg-5 col-12">
              <h3>Driver</h3>
              <ul>
                <li>Become a part of our family</li>
                <li>50 - 55 cents per mile (on all miles)</li>
                <li>Weekly pay - Direct deposit every Friday</li>
                <li>Brand new Volvo trucks and new trailers</li>
                <li>No forced dispatch</li>
                <li>24/7 dispatch –assistance at any time</li>
                <li>Fuel cards</li>
                <li>No touch freight</li>
                <li>Benefits available</li>
              </ul>

              <Application position="Driver"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default JoinUs;