import React from 'react';
import './Footer.scss';

const Footer: React.FC = () => {

  return(
    <div className="Footer">
      <p>2020 © Viking Trasportation INC. All Rights Reserved.</p>
    </div>
  )
}

export default Footer;