import React from 'react';
import './ContactUs.scss';
import { faEnvelope, faMobileAlt, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Input from '../../shared/FormElements/Input/Input';
import { useForm } from '../../shared/hooks/form-hook';
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH
} from '../../shared/util/validators';

const ContactUs: React.FC = () => {

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: '',
        isValid: false
      },
      email: {
        value: '',
        isValid: false
      },
      message: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const formSubmit = (e) => {
    e.preventDefault();

    let data = {
      subject: 'Message from Contact us form',
      name: formState.inputs.name.value,
      email: formState.inputs.email.value,
      message: formState.inputs.message.value
    }

    axios.post('http://localhost:4444/api/contact-us', data)
      .then(res => {
        // resetForm()
      })
      .catch(() => {
        console.log('Message not sent')
      })
  }

  return (
    <div className="ContactUs row no-gutters" id="contactUs">
      <div className="col-lg-5 col-12 details">
        <h1>Contact us</h1>

        <h3>Viking Trasportation INC</h3>

        <p><FontAwesomeIcon icon={faMapMarkerAlt} color='#62B6CB' /> 2021 Griffith Blvd unit E, Griffith, IN 46319, United States</p>
        <p><FontAwesomeIcon icon={faMobileAlt} color='#62B6CB' /> +1 312-550-3036</p>
        <p><FontAwesomeIcon icon={faPhone} color='#62B6CB' /> +1 219-301-0043</p>
        <p><FontAwesomeIcon icon={faEnvelope} color='#62B6CB' /> info@vikingtrans.net</p>

        <form onSubmit={(e) => formSubmit(e)}>
          <p>Please reach us...</p>
          <Input
            id="name"
            element="input"
            type="text"
            placeholder="Name"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter your name."
            onInput={inputHandler}
          />
          <Input
            id="email"
            element="input"
            placeholder="Email"
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email address."
            onInput={inputHandler}
          />
          <Input
            id="message"
            element="textarea"
            placeholder="Comment or message"
            validators={[VALIDATOR_MINLENGTH(5)]}
            errorText="Please enter your comment or message. (At least 5 charactes)"
            onInput={inputHandler}
          />
          <button type="submit" className="apply-btn" disabled={!formState.isValid}>Send</button>
        </form>
      </div>
      <div className="col-lg-7 col-12">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3525.8546550830806!2d-87.43011810766096!3d41.55823411981643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8811e7721829f97d%3A0x9b7b87202fb9740d!2sViking%20Transportation%20INC!5e1!3m2!1sen!2srs!4v1586620169985!5m2!1sen!2srs"
          frameBorder="0"
          aria-hidden="false">
        </iframe>
      </div>
    </div>
  )
}

export default ContactUs;