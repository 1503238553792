import React from 'react';
import vikingTruck from '../../assets/images/viking-truck.png';
import './Home.scss';

const Home: React.FC = () => {

  return (
    <div className='banner' id='home'>
      <img src={vikingTruck} alt='Viking truck on road' />
    </div>
  );
}

export default Home;