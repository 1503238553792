import React from 'react';
import './LeaseOrRent.scss';

const LeaseOrRent: React.FC = () => {
  return (
    <div className="LeaseOrRent row no-gutters" id="leaseOrRent">
      <div className="col-lg-7 col-12">
        <table>
          <thead>
            <tr>
              <th>EXPENSES</th>
              <th>VIKING TRANSPORTATION</th>
              <th>COMPETITION</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Truck Rent</td>
              <td>$800 + 12 cpm per week, maintenance included</td>
              <td>$1300 per week</td>
            </tr>
            <tr>
              <td>Truck Lease (New)</td>
              <td>$750 a week, maintenance included</td>
              <td>$1300 per week</td>
            </tr>
            <tr>
              <td>Liability</td>
              <td>$250 weekly</td>
              <td>$300 weekly</td>
            </tr>
            <tr>
              <td>Cargo</td>
              <td>Included</td>
              <td>$100 - $150 weekly</td>
            </tr>
            <tr>
              <td>Trailer</td>
              <td>$250 maintenance included weekly<br/>
                $175 maintenance not included weekly
              </td>
              <td>$300 maintenance included weekly<br/>
                $200 maintenance not included weekly</td>
            </tr>
            <tr>
              <td>Occupational</td>
              <td>$180 monthly</td>
              <td>$200 monthly</td>
            </tr>
            <tr>
              <td>Transflo</td>
              <td>$17.5 weekly</td>
              <td>$25 weekly</td>
            </tr>
            <tr>
              <td>Direct Deposit</td>
              <td>Included</td>
              <td>$20 - $50 monthly</td>
            </tr>
            <tr>
              <td>Physical Damage</td>
              <td>Not Included</td>
              <td>Not Included</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="col-lg-5 col-12 description">
        <h3>If you need equipment, you can</h3>
        <h1>Lease or rent trucks</h1>
        <p>We specialize in developing sensible funding solutions to put you on the road and help you grow your business.</p>
        <p>Whether you’re an owner-operator looking for the best rental program or in need of an attractive lease purchase program for your drivers, we’re here to help. We offer great and flexible lease or rent options at a very affordable price with maintence included.</p>
      </div>
    </div>
  )
};

export default LeaseOrRent;