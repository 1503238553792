import React from 'react';
import './Divider.scss';
import logo from '../../assets/images/logo.png';

const Divider: React.FC = () => {

  return(
    <div className="Divider">
      {/* <img src={logo} /> */}
      <span></span>
    </div>
  )
}

export default Divider;