import React from 'react';
import './Application.scss';
import { useForm } from '../../shared/hooks/form-hook';
import Input from '../../shared/FormElements/Input/Input';
import { VALIDATOR_REQUIRE, VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from '../../shared/util/validators';
import axios from 'axios';

const Application = props => {

  const { position } = props;
  const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: '',
        isValid: false
      },
      phone: {
        value: '',
        isValid: false
      },
      email: {
        value: '',
        isValid: false
      },
      state: {
        value: '',
        isValid: false
      },
      date: {
        value: '',
        isValid: false
      },
      message: {
        value: '',
        isValid: false
      }
    },
    false
  );

  const formSubmit = (e) => {
    e.preventDefault();

    let data = {
      subject: `Message from ${position} Application form`,
      name: formState.inputs.name.value,
      phone: formState.inputs.phone.value,
      email: formState.inputs.email.value,
      state: formState.inputs.state.value,
      date: formState.inputs.date.value,
      message: formState.inputs.message.value
    }

    axios.post('http://localhost:4444/api/application', data)
      .then(res => {
        // resetForm()
      })
      .catch(() => {
        console.log('Message not sent')
      })
  }


  return (
    <form className="Application" onSubmit={(e) => formSubmit(e)}>
      <p>Please fill out application, and soon as we get it someone from our team will contact you</p>

      <Input
        id="name"
        element="input"
        type="text"
        placeholder="Name"
        validators={[VALIDATOR_REQUIRE()]}
        errorText="Please enter your name."
        onInput={inputHandler}
      />
      <Input
        id="phone"
        element="input"
        type="tel"
        placeholder="Phone"
        validators={[VALIDATOR_REQUIRE()]}
        errorText="Please enter your phone number."
        onInput={inputHandler}
      />
      <Input
        id="email"
        element="input"
        type="email"
        placeholder="Email"
        validators={[VALIDATOR_EMAIL()]}
        errorText="Please enter a valid email address."
        onInput={inputHandler}
      />
      <Input
        id="state"
        element="select"
        placeholder="State"
        onInput={inputHandler}
        defaultOption="State"
        options={states}
        validators={[VALIDATOR_REQUIRE()]}
        errorText="Please chose you state."
      />
      <Input
        id="date"
        element="input"
        type="date"
        placeholder="Date of birth"
        validators={[VALIDATOR_REQUIRE()]}
        errorText="Please enter your birth date."
        onInput={inputHandler}
      />
      <Input
        id="message"
        element="textarea"
        rows={5}
        placeholder="Additional informations"
        validators={[VALIDATOR_MINLENGTH(5)]}
        errorText="Please enter your additional informations. (At least 5 charactes)"
        onInput={inputHandler}
      />
      <button type="submit" className="apply-btn"
        disabled={!formState.isValid}
      >Apply now</button>
    </form>
  )
}

export default Application;